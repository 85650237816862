import axios from "axios";
export default function request(confing) {
  return new Promise((reslove, reject) => {
    let installs = axios.create({
      // baseURL: "https://aide.sjzydrj.net/index.php",

      baseURL: "http://dingzhou.sjzydrj.net", //测试

      // baseURL: "https://aide.sjzydrj.net",//线上

      // withCredentials:true,
      // baseURL:'/api',
      // timeout: 1000,
    });
    // 请求拦截
    // installs.interceptors.request.use((confing) => {
    //   if (confing.params) {
    //     confing.params.auth_code = auth_code; //保存token
    //     return confing;
    //   } else {
    //     if (typeof confing.data == "string") {
    //       return confing;
    //     }
    //     confing.data.auth_code = auth_code; //保存token

    //     return confing;
    //   }
    // });
    // 响应拦截
    // installs.interceptors.response.use((confing) => {
    //   return confing.data;
    // });
    //真正的请求
    installs(confing).then((res) => {
      reslove(res);
    });
  });
}

export const baseURL = "https://aide.sjzydrj.net";
// export const baseURL = "https://aide.sjzydrj.net/lhjy/";
