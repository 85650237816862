import qs from "qs";

import request from "./request";
import requests from "./requests";


//首页轮播图
export const banner = (data) =>
  request({
    url: `/system/banner/banner_list`,
    params: data,
    method: "get",
  });

//服务费
export const price = (data) =>
  request({
    url: `/system/order/get_price`,
    params: data,
    method: "get",
  });
// 上传图片
export const getimg = (formData) =>
  request({
    // url: `/system/order/upload`,
    url: `/system/order/base64upload`,
    data: formData,
    method: "post",
  });

  export const getimgFile = (formData) =>
  request({
    url: `/system/order/upload`,
    data: formData,
    method: "post",
  });
  // 提交信息
export const order = (data) =>
  request({
    url: `/system/order/add`,
    // url: `/wechat/order/order_add`,
    data: data,
    // params: data,
    method: "post",
  });
  // 个人信息
export const sign = (data) =>
  request({
    url: `/system/order/sign`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 个人信息
export const echo = (data) =>
  request({
    url: `/system/order/sign_echo`,
    params: data,
    method: "get",
  });
  // 支付信息
export const detail = (data) =>
  request({
    url: `/system/order/order_detail`,
    // data: data,
    // method: "post",
    params: data,
    method: "get",
  });
  // 支付
export const pay = (data) =>
  request({
    url: `/system/order/order_pay`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // login
export const indexs = (data) =>
  request({
    url: `/system/Login/index`,
    data: data,
    method: "post",
  });
  // 订单信息   order/order_list
  export const list = (data) =>
  request({
    url: `/system/order/order_list`,
    // data: data,
    // method: "post",
    params: data,
    method: "get",
  });
  // 订单详情  order/order_detail
  export const details = (data) =>
  request({
    url: `/system/order/orderdetails`,
    // data: data,
    // method: "post",
    params: data,
    method: "get",
  });


  // 文章列表  
  export const notice = (data) =>
  request({
    url: `/wechat/notice/notice_list`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 文章详情
  export const noticedetail = (data) =>
  request({
    url: `/wechat/notice/notice_detail`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 发送短信验证码
  export const sms = (data) =>
  request({
    // url: `/wechat/user/sms`,
    url: `/sms/sendWithParam `,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 短信验证码效验
  export const smscodecheck = (data) =>
  request({
    // url: `/wechat/user/smscode_check`,
    url: `/sms/smscode_check`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 信息回显
  export const info = (data) =>
  request({
    url: `/wechat/user/info`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });



  // 职业技能-获取ezek openid 
  export const getopenid = (data) =>
  request({
    url: `/wechat/Skill/get_openid`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 证书列表
  export const getskillgoods = (data) =>
  request({
    url: `/wechat/Skill/get_skillgoods`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-证书+条件列表
  export const getskillgoodscondition = (data) =>
  request({
    url: `/wechat/Skill/get_skillgoods_condition`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-靠证书满足条件查询
  export const getskillgoodsconditionfind = (data) =>
  request({
    url: `/wechat/Skill/get_skillgoods_conditionfind`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-证书申报价格及补贴查询
  export const getskillgoodslevelfind = (data) =>
  request({
    url: `/wechat/Skill/get_skillgoods_levelfind`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-添加订单
  export const orderadd = (data) =>
  request({
    url: `/wechat/Skill/order_add`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-订单列表
  export const getorderlist = (data) =>
  request({
    url: `/wechat/Skill/get_orderlist`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-订单详情
  export const getorderinfo = (data) =>
  request({
    url: `/wechat/Skill/get_orderinfo`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-订单支付
  export const orderpay = (data) =>
  request({
    // url: `/wechat/Skill/order_pay`,
    url: `/wechat/Skill/order_pay`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 职业技能-订单驳回
  export const checkorder = (data) =>
  request({
    // url: `/wechat/Skill/order_pay`,
    url: `/wechat/Skill/check_order`,
    data: data,
    method: "post",
    // params: data,
    // method: "get",
  });
  // 跳转小程序
  export const getezekinfo = (data) =>
  request({
    url: `/wechat/Skill/get_ezekinfo`,
    
    // method: "post",
    method: "get",
    params: data,
  });


