<template>
  <div class="order">
    <titleNav title="订单详情" :left-arrow="true" left-text="" />
    <div class="topbacc">
      <!-- 可以支付取消注释 -->
      <!-- <div v-if="datas.status == 1">未支付</div>
      <div v-if="datas.status == 2">
        已支付({{datas.verify_status==0?'未审核':(datas.verify_status==1?'通过':'驳回(可退款)')}})
        </div>
      <div v-if="datas.status == 3">已退款</div> -->
      {{datas.verify_status==0?'未审核':(datas.verify_status==1?'通过':'驳回')}}
    </div>
    <div class="icon">
      <div>申报职业</div>
      <div>{{ goodsname }}</div>
      <!-- <div>￥{{ datas.pay_money }}</div> -->
    </div>
    <div class="information">
      <div><van-icon name="balance-list-o" />订单基本信息</div>
    </div>
    <div class="infor">
      <div>订单号编号</div>
      <!-- <div style="font-size: 14px">{{ datas.orderid }}</div> -->
      <div>{{ datas.orderid }}</div>
    </div>
    <div class="infor">
      <div>创建时间</div>
      <div>{{ datas.add_time }}</div>
    </div>
    <!-- 可以支付取消注释 -->
    <!-- <div class="infor" v-if="datas.pay_time != '' || datas.pay_time != null">
      <div>支付类型</div>
      <div>{{ datas.pay_time }}</div>
    </div>
    <div class="infor">
      <div>缴费信息</div>
      <div>{{ datas.name }}({{ datas.idcardno }})</div>
      <div style="width: 90vw">{{ datas.phone }}</div>
    </div> -->
    <!-- <button v-if="datas.status == 1" @click="details(datas.orderid, 1)">
      继续
    </button>
    <button v-else @click="details(datas.orderid, 2)">完成</button> -->
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Icon } from "vant";
import { getorderinfo, getskillgoodscondition } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Icon.name]: Icon,
  },
  data() {
    return {
      datas: {},
      year: "",
      goodsname:""
    };
  },
  mounted() {
    this.time();
    this.list();
  },
  methods: {
    time() {
      var myDate = new Date();
      this.year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    },
    list() {
      let _this=this
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        orderid: this.$route.query.orderid,
      };
      getorderinfo(data).then((res) => {
        this.datas = res.data.data;
        // this.datas.true_num = this.datas.true_num.replace(
        //   /^(.{6})(?:\d+)(.{4})$/,
        //   "$1********$2"
        // );
        let goodsid=res.data.data.goodsid
        let datas = {
          userid,
          // goodsid: res.data.data.goodsid,
        };
        getskillgoodscondition(datas).then((re) => {
          console.log(re);
          for(let i=0;i<re.data.data.length;i++){
            if(re.data.data[i].goodsid==goodsid){
              _this.goodsname=re.data.data[i].goodsname
            }
          }
        });
      });
    },
    details(e, s) {
      let id = e;
      if (s == 1) {
        this.$router.push("/pay?orderid=" + this.datas.orderid+"&id=2"+"&types=1");
      } else {
      }
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  .topbacc {
    padding-left: 4vw;
    padding-top: 25px;
    width: 100%;
    height: 100px;
    background-color: #0f81fd;
    color: #ffffff;
    font-size: 22px;
    line-height: 30px;
    font-weight: 900;
  }
  .icon {
    margin: 15px;
    font-size: 20px;
    height: 70px;
    div:nth-child(2) {
      float: left;
      line-height: 40px;
    }
    div:nth-child(3) {
      float: right;
      color: red;
      margin-top: -15px;
    }
  }
  .information {
    font-size: 20px;
    margin: 15px;
  }
  .infor {
    margin: 15px;
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    div:nth-child(1) {
      float: left;
    }
    div:nth-child(2) {
      float: right;
    }
    div:nth-child(3) {
      float: right;
      text-align: right;
    }
  }
  button {
    position: fixed;
    bottom: 10px;
    background-color: #0f81fd;
    color: #ffffff;
    border: 0;
    width: 92vw;
    margin-left: 4vw;
    font-size: 22px;
    height: 50px;
    border-radius: 5px;
  }
}
</style>